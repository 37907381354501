import React from "react";

import LogoutIcon from '@mui/icons-material/Logout';
import Cookies from "js-cookie";

import logo from "../assets/img/logo.png";
import {COLORS} from "../constants/colors";

export default function HeaderComponent(props) {
    const {isLoginPage, handleSign} = props

    return (
        <div className={`flex items-center ${isLoginPage ? "justify-center" : "justify-between"} w-screen`}>
            <button onClick={() => {
                Cookies.remove('isAuthenticated')
                Cookies.remove('lockersStatusCookies')
            }}>
                <img src={logo} alt="logo" className="object-cover"/>
            </button>
            {!isLoginPage &&
                <button className="mr-5" onClick={() => handleSign(false)}>
                    <LogoutIcon fontSize="large" sx={{color: COLORS.greenvh}}/>
                </button>
            }
        </div>
    )
}
