import React from "react";

export default function CardButtonComponent(props) {
    const {onClick, disabled, text, path, outlined} = props

    const handleClassName = () => {
        let baseClassName = "flex item-center justify-center flex-col h-full w-full p-6 border-2 border-greenvh rounded-lg shadow";

        if (outlined) {
            baseClassName = "bg-white " + baseClassName;
        } else {
            baseClassName = "bg-greenvh " + baseClassName;
        }

        return baseClassName;
    }


    const handleTextColor = () => {
        let textColor = "text-white"

        if (outlined) {
            textColor = "text-greenvh"
        }

        return textColor
    }

    return (
        <button
            style={{opacity: disabled ? 0.5 : 1}}
            disabled={disabled}
            onClick={onClick}
        >
            <div className={handleClassName()}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" data-slot="icon" className={`w-full h-8 ${handleTextColor()}`}>
                    <path d={path}/>
                </svg>
                <p className={`text-xl mt-2 ${handleTextColor()}`}>
                    {text}
                </p>
            </div>
        </button>
    )
}
