import React, {useContext, useEffect, useState} from "react";

import {useNavigate} from "react-router-dom";
import Iframe from 'react-iframe'

import LockerComponent from "../components/LockerComponent";
import HeaderComponent from "../components/HeaderComponent";

import ButtonComponent from "../components/ButtonComponent";
import CardButtonComponent from "../components/CardButtonComponent";

import {AppContext} from "../AppRouting";

export default function EndChargePage() {
    const navigate = useNavigate()
    const {
        handleSign,
        myUserId,
        lockersStatus,
        selectedLockerNumber,
        setSelectedLockerNumber,
        setIAlreadyHaveALocker,
    } = useContext(AppContext);

    const [modal, setModal] = useState(false)
    const [endModal, setEndModal] = useState(false)

    useEffect(() => {
        const iHaveOneLocker = lockersStatus.some(
            (locker) => locker.userId === myUserId || locker.globalStatus === "MINE"
        );

        if (!iHaveOneLocker) {
            setEndModal(true)
        }
    }, [lockersStatus])

    const goToSelectLockerPage = () => {
        setSelectedLockerNumber(null)
        navigate('/', {replace: true});
    }

    return (
        <div className="h-screen flex flex-col items-center justify-start px-5 py-5">
            <HeaderComponent handleSign={handleSign}/>
            <div className="py-2 grid grid-cols-2 gap-4 w-full">
                <CardButtonComponent
                    outlined
                    onClick={goToSelectLockerPage}
                    disabled={!!selectedLockerNumber}
                    text="AJOUTER UN CASIER"
                    path="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
                <CardButtonComponent
                    onClick={() => setModal(true)}
                    text="VOIR LA CAMERA"
                    path="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"
                />
            </div>
            <LockerComponent
                isEndChargePage
                title="FIN DE CHARGE"
                text="Vous pouvez mettre fin à la charge d'un équipement ou simplement ouvrir un casier en le
                    selectionnant :"
            />
            {modal && (
                <div
                    className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 py-20 px-5 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full bg-blackbg">
                    <div className="bg-white rounded-lg shadow flex flex-col items-center w-full h-full py-2 px-4">
                        <Iframe
                            url="https://odeven.fr"
                            width="100%"
                            height="100%"
                            display="block"
                            position="relative"
                            className="rounded-lg"
                        />
                        <ButtonComponent
                            onClick={() => setModal(false)}
                            text="FERMER"
                        />
                    </div>
                </div>
            )}
            {endModal && (
                <div
                    className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full bg-blackbg">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow">
                            <div className="p-4 md:p-5 text-center">
                                <h3 className="mt-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                    Merci d'avoir utiliser V'Helios.
                                    <br/>
                                    <br/>
                                </h3>
                                <ButtonComponent
                                    onClick={async () => {
                                        setIAlreadyHaveALocker(false)
                                        navigate("/", {replace : true})
                                    }}
                                    text="RETOUR A L'ACCUEIL"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

