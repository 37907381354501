import React from "react";

export default function AlertMessageComponent(props) {
    const {text} = props

    return (
        <div className="mt-5 py-2 px-4 bg-orange-100 border-2 border-orange-100 rounded-2xl">
            <p className="text-base text-orange-500">
                {text}
            </p>
        </div>
    )
}
