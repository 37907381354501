import React, {useContext} from "react";

import Cookies from 'js-cookie';
import {useNavigate} from "react-router-dom";

import HeaderComponent from "../components/HeaderComponent";
import ButtonComponent from "../components/ButtonComponent";

import {AppContext} from "../AppRouting";

export default function LoginPage() {
    const navigate = useNavigate()
    const {handleSign} = useContext(AppContext)

    const onConfirmLogin = async () => {
        await Cookies.set("isAuthenticated", "true", {expires: 365});
        void handleSign(true)
        navigate("/")
    }

    return (
        <div className="h-screen flex flex-col items-center justify-start px-5 py-5">
            <div className="h-screen flex flex-col items-center justify-start px-5 py-5 w-full">
                <HeaderComponent isLoginPage/>
                <div className="py-5 flex items-center justify-center mt-10 w-full">
                    <div className="relative py-3 w-full">
                        <div
                            className="absolute inset-0 bg-gradient-to-r from-yellow-200 to-greenvh shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 rounded-3xl"></div>
                        <div className="relative px-4 py-10 bg-white shadow-lg rounded-3xl sm:p-20">
                            <div className="max-w-md mx-auto">
                                <div>
                                    <p className="text-2xl text-greenvh">
                                        S'identifier
                                    </p>
                                </div>
                                <div className="divide-y divide-gray-200">
                                    <div
                                        className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                                        <div className="relative">
                                            <input
                                                autoComplete="off"
                                                id="email"
                                                name="email"
                                                type="text"
                                                className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:border-rose-600"
                                                placeholder="Adresse Email"
                                            />
                                            <label
                                                htmlFor="email"
                                                className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer:focus:-top-3.5 peer:focus:text-gray-600 peer:focus:text-sm"
                                            >
                                                <p className="text-base text-slate-400 mb-4">
                                                    Adresse Email
                                                </p>
                                            </label>
                                        </div>
                                        <div className="relative">
                                            <input
                                                autoComplete="off"
                                                id="password"
                                                name="password"
                                                type="password"
                                                className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:border-rose-600"
                                                placeholder="Mot de passe"
                                            />
                                            <label
                                                htmlFor="password"
                                                className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer:focus:-top-3.5 peer:focus:text-gray-600 peer:focus:text-sm"
                                            >
                                                <p className="text-base text-slate-400 mb-4">
                                                    Mot de passe
                                                </p>
                                            </label>
                                        </div>
                                        <div className="relative">
                                            <ButtonComponent
                                                onClick={onConfirmLogin}
                                                text="SE CONNECTER"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

