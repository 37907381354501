import React, {useContext, useEffect, useState} from "react";

import {useNavigate} from "react-router-dom";

import LockerComponent from "../components/LockerComponent";
import HeaderComponent from "../components/HeaderComponent";
import ButtonComponent from "../components/ButtonComponent";

import {AppContext} from "../AppRouting";

export default function SelectLockerPage() {
    const navigate = useNavigate()
    const {
        handleSign,
        myUserId,
        lockersStatus,
        selectedLockerNumber,
        setIAlreadyHaveALocker
    } = useContext(AppContext)

    const [iHaveOneLocker, setIHaveOneLocker] = useState(false)

    useEffect(() => {
        setIHaveOneLocker(lockersStatus.some(
            (locker) => locker.userId === myUserId || locker.globalStatus === "MINE"
        ))
    }, [lockersStatus])

    return (
        <div className="h-screen flex flex-col items-center justify-start px-5 py-5">
            <HeaderComponent handleSign={handleSign}/>
            <LockerComponent
                title="SELECTIONNEZ UN CASIER"
                text="Placez votre vélo devant l’un des casiers disponible et cliquez sur le numéro correspondant."
            />
            <ButtonComponent
                disabled={selectedLockerNumber !== null || !iHaveOneLocker}
                onClick={() => {
                    setIAlreadyHaveALocker(true)
                    navigate("/ending")
                }}
                text="TERMINER"
            />
        </div>
    );
}

