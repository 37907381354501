import React, {createContext, useEffect, useState} from "react";
import {Routes, Route, useNavigate} from 'react-router-dom';

import Cookies from "js-cookie";

import SelectLockerPage from "./pages/SelectLockerPage";
import EndChargePage from "./pages/EndChargePage";
import LoginPage from "./pages/LoginPage";

import {connectWebSocket} from "./utils/connectWebSocket";
import {DEFAULT_LOCKERS_STATUS} from "./constants/defaultLockersStatus";

import "./App.css";

export const AppContext = createContext();

export default function AppRouting() {
    const navigate = useNavigate()

    const myUserId = "2";

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [iAlreadyHaveALocker, setIAlreadyHaveALocker] = useState(false);
    const [errorOnOpeningLocker, setErrorOnOpeningLocker] = useState(false);
    const [waitResponse, setWaitResponse] = useState(false);
    const [isFirstOpening, setIsFirstOpening] = useState(true);

    const [selectedLockerNumber, setSelectedLockerNumber] = useState(null);
    const [lockersStatus, setLockerStatus] = useState(JSON.parse(JSON.stringify(DEFAULT_LOCKERS_STATUS)));

    useEffect(() => {
        void connectWebSocket()
        void routing()
    }, [])

    const fetchLockersStatus = async () => {
        try {
            const lockersStatusCookies = await Cookies.get("lockersStatusCookies");
            if (lockersStatusCookies) {
                setLockerStatus(JSON.parse(lockersStatusCookies))
                JSON.parse(lockersStatusCookies).forEach((locker, index) => {
                    if (locker.userId === myUserId) {
                        if (locker.globalStatus === "TIMEOUT_ERROR") {
                            setErrorOnOpeningLocker(true)
                            setSelectedLockerNumber(index + 1)
                        }
                        if (locker.doorStatus === "OPEN") {
                            navigate("/")
                            setSelectedLockerNumber(index + 1)
                        } else {
                            navigate("/ending")
                        }
                    }
                })
            }
        } catch (e) {
            console.log(e)
        }
    };

    const routing = async () => {
        try {
            const isAuthenticated = await Cookies.get("isAuthenticated");

            if (isAuthenticated === "true") {
                setIsAuthenticated(true)
                void fetchLockersStatus()
            } else {
                setIsAuthenticated(false)
                navigate("/")
            }
        } catch (e) {
            console.log(e)
        }
    };

    const handleSign = async (value) => {
        setIsAuthenticated(value)
        navigate("/")
        if (!value) {
            Cookies.remove('isAuthenticated')
        } else {
            void fetchLockersStatus()
        }
    }

    return (
        <AppContext.Provider value={{
            myUserId,
            lockersStatus,
            setLockerStatus,
            selectedLockerNumber,
            setSelectedLockerNumber,
            handleSign,
            iAlreadyHaveALocker,
            setIAlreadyHaveALocker,
            errorOnOpeningLocker,
            setErrorOnOpeningLocker,
            waitResponse,
            setWaitResponse,
            isFirstOpening,
            setIsFirstOpening
        }}>
            <Routes>
                {!isAuthenticated ?
                    <Route exact path="/" element={<LoginPage/>}/>
                    :
                    <>
                        <Route exact path="/ending" element={<EndChargePage/>}/>
                        <Route path="/" element={<SelectLockerPage/>}/>
                    </>
                }
            </Routes>
        </AppContext.Provider>
    );
}
