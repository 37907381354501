import React from "react";

export default function ButtonComponent(props) {
    const {outlined, cancelButton, onClick, disabled, text} = props

    const handleClassName = () => {
        let baseClassName = "bg-greenvh hover:bg-blue-700 text-white font-bold py-2 w-full px-8 rounded-full disabled:opacity-50";

        if (cancelButton) {
            baseClassName = "bg-white border-2 border-red-300 " + baseClassName;
        }

        if (outlined) {
            baseClassName = "bg-white border-2 border-greenvh " + baseClassName;
        }

        return baseClassName;
    }


    const handleTextColor = () => {
        let textColor = "text-white"

        if (cancelButton) {
             textColor = "text-red-300"
        }

        if (outlined) {
            textColor = "text-greenvh"
        }

        return textColor
    }

    return (
        <button
            className={handleClassName()}
            disabled={disabled}
            onClick={onClick}
        >
            <p className={`text-xl ${handleTextColor()}`}>
                {text}
            </p>
        </button>
    )
}
