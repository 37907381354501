import Cookies from "js-cookie";

import {openLocker} from "./openLocker";

export const handleConfirmSelectedLocker = async (index, myUserId, lockersStatus, setLockerStatus) => {
        void openLocker(index)

        const updatedLockersStatus = [...lockersStatus];

        updatedLockersStatus[index].globalStatus = "MINE";
        updatedLockersStatus[index].userId = myUserId;

        setLockerStatus(updatedLockersStatus)

        const lockersStatusCookies = JSON.stringify(updatedLockersStatus);
        await Cookies.set("lockersStatusCookies", lockersStatusCookies, { expires: 365 });
    };
