import axios from "axios";

export const openLocker = async (index) => {
    const url = process.env.REACT_APP_OPEN_LOCKERS_URL

    const dataToSend = {
        index: index,
        value : "\x01"
    };

    axios
        .post(url, dataToSend)
        .catch((error) => {
            console.error("Erreur lors de la requête POST:", error);
        });
};
