import React, {useState, useEffect, useContext, useRef} from "react";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import Cookies from "js-cookie";

import "../App.css";

import {handleConfirmSelectedLocker} from "../utils/handleConfirmSelectedLocker";
import {listenWebSocket} from "../utils/listenWebSocket";
import ButtonComponent from "./ButtonComponent";
import AlertMessageComponent from "./AlertMessageComponent";

import {AppContext} from "../AppRouting";

let needToClearLocker = false

export default function LockerComponent(props) {
    const {
        myUserId,
        lockersStatus,
        setLockerStatus,
        selectedLockerNumber,
        setSelectedLockerNumber,
        errorOnOpeningLocker,
        setErrorOnOpeningLocker,
        waitResponse,
        setWaitResponse,
        isFirstOpening,
        setIsFirstOpening
    } = useContext(AppContext);

    const {isEndChargePage, title, text} = props
    const timer = useRef(null)

    const [modal, setModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);

    const iconStyleBusy = {
        width: "33%",
        height: "33%",
        color: "gray",
    };

    const iconStyleMine = {
        width: "33%",
        height: "33%",
        color: "white ",
    };

    useEffect(() => {
        needToClearLocker = false

        if (isFirstOpening) {
            void determineLockersStatus();
            setIsFirstOpening(false)
        }
    }, []);

    const handleWebSocketMessage = async (event) => {
        try {
            const updatedLockersStatus = [...lockersStatus];

            const data = JSON.parse(event.data);

            const {index, value} = data

            const doorStatus = value ? "OPEN" : "CLOSE"

            if (doorStatus === "OPEN") {
                setWaitResponse(false)
                setErrorOnOpeningLocker(false)
                clearTimeout(timer.current)
                updatedLockersStatus[index].doorStatus = "OPEN"
            }

            if (index !== undefined && doorStatus === "CLOSE" && updatedLockersStatus[index].globalStatus === "MINE") {
                updatedLockersStatus[index].doorStatus = doorStatus;

                if (needToClearLocker) {
                    updatedLockersStatus[index].globalStatus = "FREE";
                    updatedLockersStatus[index].userId = "";
                }

                const lockersStatusCookies = JSON.stringify(updatedLockersStatus);
                await Cookies.set("lockersStatusCookies", lockersStatusCookies, { expires: 365 });

                setSelectedLockerNumber(null)

            }
            setLockerStatus(updatedLockersStatus)
        } catch (error) {
            console.error('Erreur lors du parsing JSON :', error);
        }
    }

    const clearLockerWithOpeningError = async () => {
        const updatedLockersStatus = [...lockersStatus];

        const index = selectedLockerNumber - 1

        updatedLockersStatus[index].globalStatus = "FREE";
        updatedLockersStatus[index].userId = "";

        setLockerStatus(updatedLockersStatus)
        setSelectedLockerNumber(null)
        setErrorOnOpeningLocker(false)
        setErrorModal(false)

        const lockersStatusCookies = JSON.stringify(updatedLockersStatus);
        await Cookies.set("lockersStatusCookies", lockersStatusCookies, { expires: 365 });
    };

    const closeModal = () => {
        setModal(false);
        !errorOnOpeningLocker && setSelectedLockerNumber(null)
    };

    const onPressLockerNumber = (index) => {
        setModal(true);
        setSelectedLockerNumber(index + 1);
    };

    const onPressButtonModal = (needToClear) => {
        needToClearLocker = needToClear

        timer.current = setTimeout(async () => {
            const index = selectedLockerNumber - 1

            if (lockersStatus[index].doorStatus === "CLOSE") {
                setErrorModal(true)
                setErrorOnOpeningLocker(true)

                const updatedLockersStatus = [...lockersStatus];
                const index = selectedLockerNumber - 1
                updatedLockersStatus[index].globalStatus = "TIMEOUT_ERROR";
                setLockerStatus(updatedLockersStatus)

                const lockersStatusCookies = JSON.stringify(updatedLockersStatus);
                await Cookies.set("lockersStatusCookies", lockersStatusCookies, { expires: 365 });
                setWaitResponse(false)
            }
        }, 5000);

        void handleConfirmSelectedLocker(selectedLockerNumber - 1, myUserId, lockersStatus, setLockerStatus)
        setModal(false);
        void listenWebSocket(handleWebSocketMessage)
        setWaitResponse(true)
    }

    const determineLockersStatus = async () => {
        const newLockersStatus = lockersStatus.map((locker) => {
            if (!locker.userId) {
                return {...locker, globalStatus: "FREE"};
            } else {
                return locker.userId === myUserId
                    ? {...locker, globalStatus: "MINE"}
                    : {...locker, globalStatus: "BUSY"};
            }

        });

        lockersStatus.forEach((locker, index) => {
            if (locker.userId === myUserId && locker.doorStatus === "OPEN") {
                setSelectedLockerNumber(index + 1)
            }
        })

        setLockerStatus(newLockersStatus);
    };

    const handleClassName = (index) => {
        let bgColor = ""
        let baseClassName = "row-span-3 aspect-square rounded-2xl relative disabled:opacity-50";
        let globalStatus = lockersStatus[index].globalStatus;
        let doorStatus = lockersStatus[index].doorStatus;

        if (globalStatus === "BUSY") {
            return `${baseClassName} bg-slate-300`;
        } else if (globalStatus === "MINE") {
            return `${baseClassName} ${doorStatus === "OPEN" ? "bg-orange-500" : "bg-greenvh"}`;
        } else if (globalStatus === "FREE") {
            bgColor = isEndChargePage ? "bg-slate-300" : "bg-green-100"
            return `${baseClassName} ${bgColor}`;
        } else if (globalStatus === "TIMEOUT_ERROR") {
            return `${baseClassName} bg-red-600`;
        }
    };

    const handleDisabledLockerButton = (index) => {
        const globalStatus = lockersStatus[index].globalStatus

        if (isEndChargePage) {
            if (errorOnOpeningLocker) {
                return index + 1 !== selectedLockerNumber || waitResponse
            } else {
                return globalStatus !== "MINE" || selectedLockerNumber !== null
            }
        } else {
            if (errorOnOpeningLocker) {
                return index + 1 !== selectedLockerNumber
            } else {
                return globalStatus === "BUSY" || globalStatus === "MINE" || globalStatus === "TIMEOUT_ERROR" || selectedLockerNumber !== null
            }
        }
    };

    const handleStyleLockerButton = (index) => {
        const globalStatus = lockersStatus[index].globalStatus

        if (isEndChargePage) {
            if (globalStatus === "MINE" || globalStatus === "TIMEOUT_ERROR" || selectedLockerNumber === index + 1) {
                return {
                    opacity: 1
                }
            } else {
                return {
                    opacity: 0.25
                }
            }
        } else {
            return {
                opacity: globalStatus === "FREE" || globalStatus === "MINE" || globalStatus === "TIMEOUT_ERROR" ? 1 : 0.25
            };
        }
    };

    const handleDetermineIconLockerButton = (index) => {
        const doorStatus = lockersStatus[index].doorStatus

        return doorStatus === "OPEN" ? (
            <LockOpenOutlinedIcon sx={iconStyleMine}/>
        ) : (
            <LockOutlinedIcon sx={iconStyleMine}/>
        );
    };

    const renderLockerContent = (index) => {
        const globalStatus = lockersStatus[index].globalStatus;

        if (waitResponse && index + 1 === selectedLockerNumber)
            return (
                <div role="status">
                    <svg aria-hidden="true"
                         className="inline w-8 h-8 text-white animate-spin fill-yellowvh"
                         viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"/>
                        <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"/>
                    </svg>
                    <span className="sr-only">Loading...</span>
                </div>
            )
        if (globalStatus === "BUSY") {
            return <LockOutlinedIcon sx={iconStyleBusy}/>;
        } else if (globalStatus === "MINE" || globalStatus === "TIMEOUT_ERROR") {
            return handleDetermineIconLockerButton(index);
        } else {
            return renderLockerText(index);
        }
    };

    const renderLockerText = (index) => (
        <p className={`text-3xl ${isEndChargePage ? 'text-gray-500' : 'text-greenvh'}`}>
            {index + 1}
        </p>
    );

    const lockerButtons = lockersStatus.map((locker, index) => (
        <button
            key={index}
            onClick={() => onPressLockerNumber(index)}
            className={handleClassName(index)}
            disabled={handleDisabledLockerButton(index)}
            style={handleStyleLockerButton(index)}
        >
            {renderLockerContent(index)}
        </button>
    ));

    return (
        <>
            <div className="py-4">
                <p className="text-2xl text-greenvh">{title}</p>
                <p className="text-base mt-2 text-slate-400 mb-4">
                    {text}
                </p>
                <div className="grid grid-cols-3 grid-flow-row gap-4">
                    {lockerButtons}
                </div>
                {selectedLockerNumber && !errorOnOpeningLocker && !waitResponse && !modal && (
                    <AlertMessageComponent
                        text={isEndChargePage
                            ? `Le casier n°${selectedLockerNumber} est ouvert : refermer votre casier afin de libérer ou conserver celui ci.`
                            : `Le casier n°${selectedLockerNumber} est ouvert : branchez votre vélo puis refermez la porte pour initier la recharge`
                        }
                    />
                )}
            </div>
            {modal && (
                <div
                    className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full bg-blackbg">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow">
                            <div className="p-4 md:p-5 text-center">
                                <h3 className="mt-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                    Veuillez confirmer la selection et l'ouverture du casier numéro n°{selectedLockerNumber}.<br/><br/>
                                    {isEndChargePage && "Vous pouvez choisir de conserver ce casier ou de le libérer :"}
                                    <br/>
                                </h3>
                                <div className={`grid ${isEndChargePage ? "grid-rows-3" : "grid-rows-2"} gap-4 mt-5`}>
                                    {isEndChargePage &&
                                        <ButtonComponent
                                            outlined
                                            onClick={() => onPressButtonModal(true)}
                                            text="LIBERER"
                                        />
                                    }
                                    <ButtonComponent
                                        onClick={() => onPressButtonModal(false)}
                                        text={isEndChargePage ? "CONSERVER" : "CONFIRMER"}
                                    />
                                    <ButtonComponent
                                        cancelButton
                                        onClick={closeModal}
                                        text="ANNULER"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {errorModal && (
                <div
                    className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full bg-blackbg">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow">
                            <div className="p-4 md:p-5 text-center">
                                <h3 className="mt-5 text-lg font-normal text-gray-500 dark:text-gray-400 mb-2">
                                    OUPS !! Il semble qu'un obstacle bloque l'accès au casier. Veuillez vous assurer
                                    qu'aucun objet n'entrave la porte. Fermez ce message, puis sélectionnez à nouveau le
                                    casier pour l'ouvrir. Si le problème persiste, veuillez contacter le numéro indiqué
                                    sous le QR Code.
                                    {!isEndChargePage && " Vous pouvez également cliquer sur \"Choisir un autre casier\"."}
                                    <br/>
                                </h3>
                                <div className={`grid ${isEndChargePage ? "grid-rows-1" : "grid-rows-2"} gap-4 mt-5`}>
                                    <ButtonComponent
                                        onClick={() => setErrorModal(false)}
                                        text="FERMER"
                                    />
                                    {!isEndChargePage &&
                                        <ButtonComponent
                                            cancelButton
                                            onClick={() => clearLockerWithOpeningError()}
                                            text="CHOISIR UN AUTRE CASIER"
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
